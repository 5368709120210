import React from 'react';

export const MenuContext = React.createContext();

export function MenuProvider(props) {
  const [menuActions, dispatchMenuActions] = React.useReducer(reducerMenuActions, { });
  const [returnAction, setreturnAction] = React.useState(() => {});
  const { app } = props;

  function reducerMenuActions(currentState, dispatch) {
    let finalMenuActions;
    switch (dispatch.type) {
      case 'add':
        finalMenuActions = { ...currentState };
        dispatch.actions.forEach((action) => {
          finalMenuActions[action.name] = action.callback;
        });
        return finalMenuActions;
      case 'set':
        finalMenuActions = { };
        dispatch.actions.forEach((action) => {
          finalMenuActions[action.name] = action.callback;
        });
        return finalMenuActions;
      case 'clear':
        return {};
      default:
        throw new Error();
    }
  }

  const value = React.useMemo(() => ({
    menuActions: { ...menuActions },
    dispatchMenuActions,
    returnAction,
    setreturnAction,
  }), [menuActions, returnAction]);

  return (
    <MenuContext.Provider value={value}>
      {app}
    </MenuContext.Provider>
  );
}
