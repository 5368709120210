import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';

export default function NotYetSetup() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box sx={{
      height: '50vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 2,
    }}
    >
      <Typography component="span">
        {t('setup.not_yet_setup_info')}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => navigate('/contact')}
        startIcon={<EmailIcon />}
      >
        contact
      </Button>
    </Box>
  );
}
