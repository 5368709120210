import React, { useState, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMenu from 'menu-actions/useMenu';
import SendCertificateDialog from 'components/SendCertificateDialog';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import StoragePDFViewer from './StoragePDFViewer';
import { useQuery } from './utils';

export default function StoragePDFViewerPage(props) {
  const query = useQuery(React.useMemo, useLocation);
  const tankPath = query.get('filePath');
  const { dispatchMenuActions } = useMenu();
  const { t } = useTranslation();
  const [sendCertifDialogOpen, setsendCertifDialogOpen] = useState(false);
  const [docBlob, setdocBlob] = useState(undefined);

  const downloadPdfFromUrl = useCallback((url) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.target = '_blank';
    a.download = 'certificate.pdf';
    a.click();
    a.remove();
  }, []);

  const downloadPdf = useCallback((url, blob) => {
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = blobUrl;
    a.target = '_blank';
    a.download = 'certificate.pdf';
    a.click();
    a.remove();
    // Added this line to revoke the blob URL after its usage
    URL.revokeObjectURL(blobUrl);
  }, []);

  const printPdf = useCallback((url, blob) => {
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;

    iframe.onload = function () {
      setTimeout(() => {
        try {
          // Try to call the print function
          iframe.contentWindow.print();
        } catch (error) {
          // If print function is blocked, display an error
          console.error('Print function failed.', error);
        }
      }, 0);
    };

    // Append the iframe to the body
    document.body.appendChild(iframe);
  }, []);

  const handleOpenSendCertificate = useCallback((url, blob) => {
    setdocBlob(blob);
    setsendCertifDialogOpen(true);
  }, []);

  const actions = useMemo(
    () => [
      {
        name: t('download'),
        action: downloadPdf,
        icon: <DownloadIcon />,
      },
      {
        name: t('print'),
        action: printPdf,
        icon: <PrintIcon />,
      },
      {
        name: t('send_email'),
        action: handleOpenSendCertificate,
        icon: <AttachEmailIcon />,
      },
    ],
    [downloadPdf, handleOpenSendCertificate, printPdf, t],
  );

  const downloadPDFAction = (blobUrl) => ({
    name: t('download'),
    callback: () => downloadPdfFromUrl(blobUrl),
  });

  const pathSegments = tankPath.split('/');

  return (
    <>

      <StoragePDFViewer
        path={tankPath}
        actionButtons={actions}
        loadedCallback={(blobUrl) => dispatchMenuActions(
          { type: 'set', actions: [downloadPDFAction(blobUrl)] },
        )}
      />
      <SendCertificateDialog
        open={sendCertifDialogOpen}
        setOpen={setsendCertifDialogOpen}
        docBlob={docBlob}
        docName={pathSegments[pathSegments.length - 1]}
      />
    </>
  );
}
